import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import Button from "./Button/Button";
import Link from "next/dist/client/link";
import Column from "./atoms/Column";
import { MT } from "./MarvyTypography";
import { useAppState } from "lib/appState";
import { subscribe } from "lib/stripe";
import { useRouter } from "next/router";
import LoaderMarvy from "./atoms/LoaderMarvy";

function SubscribeButton({ priceId, children }) {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      gradient
      onClick={async () => {
        setLoading(true);
        const redirect = await subscribe(priceId);
        redirect();
      }}
      disabled={loading}
    >
      {children}
      {loading && <LoaderMarvy />}
    </Button>
  );
}

export default function MarvyPricingTable({
  plans,
  rows,
  customFields,
  trial,
  ...props
}) {
  function buildPlanCTA(plan, planIndex) {
    if (!appState.user) {
      if (plan.price === 0) {
        return (
          <Link href="/auth/register">
            <Button gradient>Register</Button>
          </Link>
        );
      }
      return (
        <>
          <Button
            gradient
            onClick={() => {
              console.log("setting requested plan");
              setAppState({
                ...appState,
                requestedPlan: plan.defaultPriceId,
              });
              router.push("/auth/register");
            }}
          >
            Subscribe
          </Button>
          {trial[planIndex] ? (
            <>
              <br />
              {`${trial[planIndex]} days free trial`}
            </>
          ) : null}
        </>
      );
    }
    if (plan.price > 0) {
      console.log(plan);
      if (
        !appState.subscriptions?.find((sub) => sub.productId === plan.productId)
      ) {
        return (
          <>
            <SubscribeButton priceId={plan.defaultPriceId}>
              Subscribe
            </SubscribeButton>
            {trial[planIndex] ? (
              <>
                <br />
                {`${trial[planIndex]} days free trial`}
              </>
            ) : null}
          </>
        );
      } else {
        return (
          <MT sx={{ fontSize: "0.75rem" }}>You are subscribed to this plan</MT>
        );
      }
    }
  }
  const [appState, setAppState] = useAppState();
  const router = useRouter();
  return (
    <>
      <Table
        {...props}
        sx={{
          display: { xs: "none", sm: "table" },
          backgroundColor: "rgba(255, 255, 255, 0.85)",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {plans.map((plan) => (
              <TableCell key={plan.id} align="center">
                <b>{plan.name}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.indentationLevel
                    ? Array(row.indentationLevel).fill(
                        <Box sx={{ display: "inline-block", width: "30px" }} />
                      )
                    : null}
                  {row.name}
                </TableCell>
                {plans.map((plan, planIndex) => {
                  let item;
                  if (row.modifier) {
                    if (row.type === "feature") {
                      item = row.modifier(plan.features[row.key]);
                    } else if (row.type === "quota") {
                      item = row.modifier(plan.quotas[row.key]);
                    }
                  } else {
                    if (row.type === "feature") {
                      item = plan.features[row.key] ? "✅" : "❌";
                    } else if (row.type === "quota") {
                      item = plan.quotas[row.key];
                    } else if (row.type === "custom") {
                      if (
                        typeof customFields[planIndex][row.key] === "boolean"
                      ) {
                        item = customFields[planIndex][row.key] ? "✅" : "❌";
                      } else {
                        item = customFields[planIndex][row.key];
                      }
                    }
                  }
                  return (
                    <TableCell key={plan.id} align="center">
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <b>Price</b>
            </TableCell>
            {plans.map((plan, planIndex) => (
              <TableCell key={plan.id} align="center">
                {plan.price === 0
                  ? "Free"
                  : `${plan.currencySymbol}${plan.price} / ${plan.period}`}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ borderBottom: "none" }}></TableCell>
            {plans.map((plan, index) => (
              <TableCell
                sx={{ borderBottom: "none" }}
                key={plan.id}
                align="center"
              >
                {buildPlanCTA(plan, index)}
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
      <Column sx={{ display: { xs: "flex", sm: "none" }, gap: 4 }}>
        {plans.map((plan, planIndex) => (
          <Card
            sx={{
              width: 300,
              p: 2,
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              gap: 2,
            }}
            variant="outlined"
          >
            <MT sx={{ fontSize: "1.5rem", fontWeight: 600 }}>{plan.name}</MT>
            <MT align="center">
              {plan.price === 0 ? (
                "Free"
              ) : (
                <>
                  {plan.price} {plan.currencySymbol}/{plan.period}
                </>
              )}
              {trial[planIndex] ? (
                <>
                  <br />
                  {`${trial[planIndex]} days free trial`}
                </>
              ) : null}
            </MT>
            {rows.map((row) => {
              let item;
              if (row.modifier) {
                if (row.type === "feature") {
                  item = row.modifier(plan.features[row.key]);
                } else if (row.type === "quota") {
                  item = row.modifier(plan.quotas[row.key]);
                }
              } else {
                if (row.type === "feature") {
                  item = plan.features[row.key] ? "✅" : "❌";
                } else if (row.type === "quota") {
                  item = plan.quotas[row.key];
                } else if (row.type === "custom") {
                  if (typeof customFields[planIndex][row.key] === "boolean") {
                    item = customFields[planIndex][row.key] ? "✅" : "❌";
                  } else {
                    item = customFields[planIndex][row.key];
                  }
                }
              }
              return (
                <MT align="center">
                  {row.name}: {item}
                </MT>
              );
            })}
            {buildPlanCTA(plan, planIndex)}
          </Card>
        ))}
      </Column>
    </>
  );
}
