import { Typography } from "@mui/material";
import { createContext, useContext } from "react";

const SXContext = createContext({});

function MarvyTypography({ sx, ...props }) {
  const inheritedSx = useContext(SXContext);
  const finalSx = { ...inheritedSx, ...sx };
  return (
    <SXContext.Provider value={finalSx}>
      <Typography sx={finalSx} {...props} />
    </SXContext.Provider>
  );
}

function MTProvider({ sx, ...props }) {
  const inheritedSx = useContext(SXContext);
  const finalSx = { ...inheritedSx, ...sx };
  return (
    <SXContext.Provider value={finalSx} {...props}>
      {props.children}
    </SXContext.Provider>
  );
}

export default MarvyTypography;
const MT = MarvyTypography;
export { MT, MTProvider };
